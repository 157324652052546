<template>
  <div style="position: relative; height: 100%; width:100%; border-radius: 8px;z-index:2!important">
    <div :id="key" style="position: relative; height: 100%; width:100%; border-radius: 8px;z-index:2!important"></div>
    <v-btn
      style="z-index:3;position:absolute; bottom:2px;left:2px"
      class="elevation-0 ma-2"
      color="bgContainer"
      height="38"
      min-height="0"
      min-width="0"
      width="38"
      small
    >
      <v-icon @click="fitBounds()">
        mdi-crosshairs-gps
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import L from 'leaflet'
import utils from '@/utils/genUUID'
import 'leaflet/dist/leaflet.css'

let map
export default {
  name: 'MapPreview',
  data() {
    return {
      geojsonLayer: undefined,
      pointLayer: undefined,
    }
  },
  props: {
    id: {
      required: true,
    },
    geojson: {
      required: true,
    },
    point: {
      type: Array,
      required: false,
    },
  },
  watch: {
    geojson(val) {
      if (this.geojsonLayer) map.removeLayer(this.geojsonLayer)
      if (val) {
        this.geojsonLayer = L.geoJson(this.geojson, {
          style: {
            color: 'yellow',
            weight: 2,
            opacity: 1,
          },
        }).addTo(map)
        map.fitBounds(this.geojsonLayer.getBounds(), { padding: [20, 20] })
      } else {
        this.fitWorldBounds()
      }
    },
    point(val) {
      if (this.pointLayer) map.removeLayer(this.pointLayer)
      if (val) {
        this.pointLayer = L.marker(this.point).addTo(map)
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeMap()
    })
  },
  methods: {
    initializeMap() {
      map = L.map(this.key, {
        zoomControl: false,
        scrollWheelZoom: true,
        attributionControl: false,
      }).setView([51.505, -0.09], 5)

      L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        maxZoom: 17,
        tileSize: 256,
        minZoom: 0,
      }).addTo(map)

      // map.dragging.disable()
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      })
      if (this.geojson) {
        this.geojsonLayer = L.geoJson(this.geojson, {
          style: {
            color: 'yellow',
            weight: 2,
            opacity: 1,
          },
        }).addTo(map)
        map.fitBounds(this.geojsonLayer.getBounds(), { padding: [20, 20] })
      } else {
        this.fitWorldBounds()
      }
      if (this.point) {
        this.pointLayer = L.marker(this.point).addTo(map)
      }
    },

    fitWorldBounds() {
      const worldBounds = [
        [-90, -180],
        [90, 180],
      ]
      map.fitBounds(worldBounds, { padding: [50, 50] })
    },
    fitBounds() {
      map.fitBounds(this.geojsonLayer.getBounds(), { padding: [20, 20] })
    },
  },
  computed: {
    key() {
      return utils.getUUID() + this.id
    },
  },
}
</script>

<style scoped></style>
