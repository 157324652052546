<template>
  <v-layout class="fill-height" column>
    <v-layout class="fill-height">
      <div style="width: 400px; height: 100%; flex: none;">
        <v-card class="AOI-menu custom-card-bg-1 overflow-y-hidden" height="100%" width="100%">
          <v-layout class="fill-height" column>
            <v-card class="custom-card-bg-0 overflow-y-auto overflow-x-hidden" style="text-align: center;" width="100%">
              <v-card-title class="py-3">
                AOI Information
                <v-spacer />
              </v-card-title>
              <v-card-text v-if="AOIData" class="pb-1">
                <v-layout align-start class="pl-2" column>
                  <div
                    v-for="(value, key) of AOIData"
                    v-show="key !== 'information' && key !== 'uuid'"
                    :key="key"
                    style="font-size: 15px"
                  >
                    <b class="text-capitalize">{{ key }}:</b>
                    {{ key === 'area' ? transformArea(value) : value }}
                  </div>
                </v-layout>
                <div v-if="AOIGeojson" class="mt-5 px-1" style="position: relative;width: 100%;padding-top: 75%;">
                  <div
                    style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100% ; height: 100%; border-radius: 8px"
                  >
                    <MapPreview :id="'AOI'" :geojson="AOIGeojson" />
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <div v-if="statistic" class="pt-3 px-5" style="flex: none; width: 100%; height: fit-content">
              <div style="height: 24px">
                <span>
                  <b class="pr-1">Max Upward:</b> {{ statistic.max > 0 ? statistic.max : 0 }}
                  <span style="font-size: 12px">mm</span></span
                >
              </div>
              <div style="height: 24px">
                <span>
                  <b class="pr-1">Max Downward:</b> {{ statistic.min < 0 ? statistic.min : 0 }}
                  <span style="font-size: 12px">mm</span></span
                >
              </div>
              <div style="height: 24px">
                <span> <b class="pr-1">Average:</b> {{ statistic.average.toFixed(2) }} </span>
                <span style="font-size: 12px">mm</span>
              </div>
              <div style="height: 24px">
                <span>
                  <b class="pr-1">Total:</b> {{ statistic.total }}
                  <span style="font-size: 12px">points</span>
                </span>
              </div>
            </div>
            <v-spacer />
            <div class="pa-3" style="flex: none; height: 300px; width: 100%">
              <canvas id="primaryChart" height="auto" width="100%"></canvas>
            </div>
          </v-layout>
        </v-card>
      </div>
      <v-layout class="fill-height pl-4" column>
        <div class="pb-2" style="width: 100%; flex: none">
          <v-layout class="fill-height">
            <v-col class="d-flex align-center pt-4 pl-0" cols="12" lg="4">
              <v-select
                v-model="currentAOI"
                :items="AOIs"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                label="Select AOI"
                outlined
                placeholder="AOI"
                return-object
                @change="getService"
              />
            </v-col>
            <v-col class="d-flex align-center pt-4" cols="12" lg="4">
              <v-select
                v-model="source"
                :disabled="!Object.keys(service).length"
                :items="sources"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                label="Select source"
                outlined
                placeholder="Source"
                return-object
                @change="getListResult"
              />
            </v-col>
            <v-col class="align-center pt-4" cols="12" lg="4">
              <v-select
                v-model="result"
                :disabled="!source"
                :items="data"
                :loading="loading"
                dense
                hide-details
                item-text="date"
                label="Date"
                outlined
                placeholder="Date"
                return-object
                @change="filterData"
              >
              </v-select>
            </v-col>
            <v-col class="align-center pt-4" cols="12" lg="0"></v-col>
          </v-layout>
        </div>
        <v-card height="100%" style="position: relative" width="100%">
          <Map ref="map" :current-layers.sync="layers" :isDraw="false" @featureInfo="getFeatureInfo" />
          <div style="width: 55px; height: 280px; max-height: 90%; position: absolute; top: 10px; left: 20px">
            <div style="width: 10px; height: 100%;" v-bind:style="{ 'background-image': gradient }"></div>
            <div
              v-for="(item, index) in gradientColor"
              v-show="index % 2 === 0"
              style="position: absolute; right: 0; width: 100%; height: 1px; border: 1px solid #eaeaea; font-size: 11px; color: #eaeaea; text-align: end"
              v-bind:style="{ top: 100 - index * 5 + '%' }"
            >
              {{ item.value.toFixed(2) }}
            </div>
          </div>
        </v-card>
        <div class="pt-4" style="width: 100%; max-height: 300px; overflow: hidden; flex: none">
          <v-card class="custom-card-bg-1 position-relative" height="100%" width="100%">
            <v-layout class="fill-height pa-2" column>
              <div style="flex: none; width: 100%; height: fit-content; min-height: 0">
                <v-layout align-center class="fill-height">
                  <div v-if="!chart">No data</div>
                  <v-spacer />
                  <v-btn :disabled="!chart" icon small @click="changeDisplayChart">
                    <v-icon>{{ showable ? 'icon-chevrons_down' : 'icon-chevrons_up' }}</v-icon>
                  </v-btn>
                </v-layout>
              </div>
              <v-layout v-show="showable" align-center style="flex: 1; min-height: 230px">
                <div style=" width: 275px; height: 100%;  border-right: 1px solid #2F3241">
                  <v-layout align-center class="fill-height pl-3">
                    <v-card v-if="infoFeature" class="custom-card-bg-0 position-relative" width="100%">
                      <h3 class="py-2">Feature information</h3>
                      <div class="py-2">Longitude: {{ infoFeature.lng }}</div>
                      <div class="py-2">Latitude: {{ infoFeature.lat }}</div>
                      <div class="py-2">Average: {{ infoFeature.avg }}</div>
                    </v-card>
                  </v-layout>
                </div>
                <v-layout class="fill-height pl-2" style="flex: 1">
                  <canvas id="chart" height="auto" width="100%"></canvas>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-card>
        </div>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { mapState } from '@/store/ults'
import { getOrder } from '@/api/order'
import { getDetailAOI } from '@/api/aoi-api'

import {
  getChartLandSubsidence,
  getFeatureInfoLandSubsidence,
  getLandSubsidenceLayer,
  getStatisticLandSubsidence,
} from '@/api/landsubsidence-api'
import { getChangeDetectionResult, getFeature } from '@/api/change-detection-api'
import AreaConvert from '@/utils/textArea'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import CustomIcon from '@/components/CustomIcon.vue'
import MapPreview from '@/components/MapPreview.vue'
import Chart from 'chart.js/auto'
import GradientColor from '@/utils/genGradiendColor'
import Calculate from '@/utils/mathCulculate'

export default {
  name: 'LandSubsidence',
  components: { MapPreview, CustomIcon, LayerControl, Map },
  data() {
    return {
      chart: undefined,
      primaryChart: undefined,
      labels: [],
      chartData: [],
      AOIData: undefined,
      AOIGeojson: undefined,
      showable: false,
      statistic: undefined,
      dataLoading: false,
      loading: false,
      layers: [],
      source: undefined,
      currentAOI: undefined,
      leftMenu: true,
      sources: [],
      service: {},
      data: [],
      result: undefined,
      changeData: [],
      infoFeature: undefined,
      gradientColor: [],
      filterStyle: [],
      firstLoad: true,
    }
  },
  props: {},
  computed: {
    ...mapState('AOI', ['AOIs']),
    gradient() {
      return 'linear-gradient(' + this.gradientColor.map(val => val.color).toString() + ')'
    },
  },
  mounted() {
    this.getListAOI()
  },
  watch: {
    showable() {
      this.$refs.map.reSize()
    },
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  methods: {
    changeDisplayChart() {
      this.showable = !this.showable
    },
    async getFeatureInfo(features) {
      try {
        this.loading = true
        try {
          const res = await getFeatureInfoLandSubsidence({
            id: this.currentAOI.uuid,
            projectId: this.$route.params.id,
            featureId: features[0].properties.id,
            payload: { vector_uuid: this.result.uuid },
          })
          this.showable = true

          this.infoFeature = {
            lng: res.data.info.geojson.coordinates[0],
            lat: res.data.info.geojson.coordinates[1],
            avg: Calculate.average(res.data.chart.values),
          }
          if (!this.chart) this.displayChart(res.data.chart, 'chart')
          else {
            this.transformData(res.data.chart)
            this.updateChartData(this.chartData, 'chart')
          }
        } catch (e) {
          console.log(e)
        } finally {
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        this.currentAOI = this.AOIs[0]
        if (this.currentAOI) await this.getService()
        else this.firstLoad = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getService() {
      try {
        this.loading = true
        this.resetData()
        this.$refs.map.submitZoom(this.currentAOI.bbox)
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.currentAOI.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.service = res.data[0]
        this.sources = []
        res.data.forEach(val => this.sources.push(val.image_source))
        this.source = this.sources[0]
        await this.getListResult()
        await this.displayAOI()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListResult() {
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.data = []
        const res = await getChangeDetectionResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.data = res.data
        if (this.firstLoad && this.data.length) {
          this.result = this.data[0]
          await this.filterData()
        }
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async filterData() {
      this.getChart()
      await this.getStatistic()
      await this.getLayers()
    },
    async getStatistic() {
      try {
        const res = await getStatisticLandSubsidence({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: { vector_uuid: this.result.uuid },
        })
        let colors = GradientColor.generate_colors(['#002cde', '#00d2b2', '#35c400', '#d2b700', '#c40000'], 21)
        const max = Math.abs(res.data.max) > Math.abs(res.data.min) ? Math.abs(res.data.max) : Math.abs(res.data.min)
        const min = Math.abs(res.data.max) > Math.abs(res.data.min) ? -Math.abs(res.data.max) : -Math.abs(res.data.min)
        let step = (max.toFixed(2) - min.toFixed(2)) / 20
        this.filterStyle = ['case']
        for (let i = 0; i < 21; i++) {
          this.gradientColor.push({
            color: colors[i].color,
            value: min + i * step,
          })
          if (i > 0)
            this.filterStyle.push(
              ['<=', ['get', '2022-11-06'], (min + i * step) / 1000],
              colors[colors.length - i].color,
            )
        }
        this.filterStyle.push('#ffc400')
        this.statistic = res.data
      } catch (e) {
      } finally {
      }
    },
    async getChart() {
      try {
        const res = await getChartLandSubsidence({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: { vector_uuid: this.result.uuid },
        })
        if (!this.primaryChart) this.displayChart(res.data, 'primaryChart')
        else {
          this.transformData(res.data)
          this.updateChartData(this.chartData, 'primaryChart')
        }
      } catch (e) {
      } finally {
      }
    },
    async getLayers() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        const res = await getLandSubsidenceLayer({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: { source: this.source },
        })
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              display: true,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.addImageToMap(image)
          } else {
            this.$refs.map.addVectorTiles({
              display: true,
              data: val.tile_info,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
              layerName: 'default',
              paint: {
                'circle-color': this.filterStyle,
                'circle-opacity': 1,
                'circle-radius': 5,
                'circle-stroke-color': this.filterStyle,
                'circle-stroke-opacity': 1,
                'circle-stroke-width': 2,
              },
              name: val.name,
              id: val.name,
              type: val.tile_info.styles.type,
            })
            let vector = this.layers.find(val => val.dataId === val.name)
            this.$refs.map.addHoverToLayer(vector.id)
          }
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addLayer(feature) {
      let featureDetail = await this.getFeature(feature)
      this.$refs.map.displayVector(
        '#ff0015',
        featureDetail.geojson,
        featureDetail.geojson.type,
        '#ff0015',
        'change-id-' + featureDetail.id,
        false,
        featureDetail.geojson.type,
      )
      this.$refs.map.submitZoom(bbox(featureDetail.geojson))
      await sleep(800)
      this.$refs.map.removeLayer('change-id-' + featureDetail.id)
    },
    async getFeature(feature) {
      try {
        this.loading = true
        const res = await getFeature({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          featureId: feature.id,
          payload: {},
        })
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async displayAOI() {
      try {
        if (!this.currentAOI) this.AOIGeojson = undefined
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.AOIData = {
          area: this.currentAOI.area,
          name: this.currentAOI.name,
          group: this.currentAOI.group,
        }
        this.AOIGeojson = res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    transformArea(area, withUnit = true) {
      return AreaConvert.covertArea(area, 'ha', withUnit)
    },
    resetData() {
      this.source = undefined
      this.changeData = []
      this.data = []
      this.result = undefined
    },
    transformData(chartInfo) {
      this.chartData = []
      this.labels = []
      this.labels = chartInfo.labels
      this.chartData.push({
        type: 'line',
        label: '',
        data: chartInfo.values,
        backgroundColor: 'blue',
        borderColor: '#ff0015',
        borderWidth: 2,
        pointBorderWidth: 4,
        pointHitRadius: 6,
        pointBackgroundColor: '#0000ff',
      })
    },
    displayChart(chartInfo, chartId) {
      this.transformData(chartInfo)
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      let ctx = document.getElementById(chartId)
      this[chartId] = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          title: {
            color: '#d4d4d4',
            display: true,
            text: '',
          },
          scales: {
            x: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
            y: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              title: {
                color: '#d4d4d4',
                text: 'mm',
                display: true,
              },
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    updateChartData(newData, chartId) {
      this[chartId].data.datasets = newData
      this[chartId].data.labels = this.labels
      this[chartId].update()
    },
  },
}
</script>

<style scoped></style>
