import api from '@/api/api'
import config from '@/config'

export async function getLandSubsidenceLayer(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/vectors/${data.vectorId}/land-subsidence`,
    data.payload,
  )
}

export async function getChartLandSubsidence(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/chart/land-subsidence`,
    data.payload,
  )
}

export async function getStatisticLandSubsidence(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors/${data.vectorId}/dashboard/land-subsidence`,
    data.payload,
  )
}

export async function getFeatureInfoLandSubsidence(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/chart/land-subsidence/features/${data.featureId}`,
    data.payload,
  )
}
